import React from "react"
import CookieConsent from "react-cookie-consent"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/Header"
import Problem from "../components/sections/Problem"
import Solution from "../components/sections/Solution"
import Team from "../components/sections/Team"
import Footer from "../components/sections/Footer"
import GetStarted from "../components/sections/Getstarted"

import theme from "../styles/theme"

const IndexPage = () => (
  <Layout>
    <CookieConsent
      location="bottom"
      buttonText="OK"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      buttonStyle={{
        backgroundColor: theme.color["primary-500"],
        color: theme.color.white,
        fontFamily: theme.font.bold,
        borderRadius: "4px",
      }}
    >
      We use cookies to improve this website.
    </CookieConsent>
    <SEO title="Better decisions with data" />
    <Navigation />
    <Header />
    <Problem />
    <Solution />
    <Team />
    <GetStarted />
    <Footer />
  </Layout>
)

export default IndexPage
