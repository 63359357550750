import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Person from "../common/sections/Person"
import {
  SectionTitle,
  Subtitle,
  StyledContainer,
  SectionText,
} from "../common/sections/styles"

import { Section } from "../global"
import { Spacing } from "../common/Spacing"

const Team = () => {
  const query = usePhotosQuery()
  return (
    <Section id="team">
      <StyledContainer>
        <Subtitle>Team</Subtitle>

        <SectionTitle>People behind the solution</SectionTitle>

        <SectionText>
          Evexia was founded in January 2020 after an idea formed in a hackathon
          grew too large to be only a concept. We are a group of students from
          Aalto University and the University of Helsinki passionate about
          making the world better place with the help of technology. Together,
          we make up a multidisciplinary team that is eager to learn and grow
          together.
        </SectionText>
        <Spacing dir="y" amount="xxLarge" />

        <TeamGrid>
          <TeamItem>
            <Person
              name="Aleksi Pauna"
              role="Business | Co-founder"
              linkedIn="https://www.linkedin.com/in/aleksipauna/"
              imageQuery={query.aleksi.childImageSharp.fluid}
            />
          </TeamItem>

          <TeamItem>
            <Person
              name="Otto Laitinen"
              role="Product design | Co-founder"
              linkedIn="https://www.linkedin.com/in/otto-a-laitinen/"
              imageQuery={query.otto.childImageSharp.fluid}
            />
          </TeamItem>

          <TeamItem>
            <Person
              name="Olli Warro"
              role="Technology | Co-founder"
              linkedIn="https://fi.linkedin.com/in/olli-warro"
              imageQuery={query.olli.childImageSharp.fluid}
            />
          </TeamItem>

          <TeamItem>
            <Person
              name="Juuso Kylmäoja"
              role="Data science | Co-founder"
              linkedIn="https://www.linkedin.com/in/juuso-kylmaoja/"
              imageQuery={query.juuso.childImageSharp.fluid}
            />
          </TeamItem>
        </TeamGrid>
      </StyledContainer>
    </Section>
  )
}
export default Team

const TeamGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-self: center;
  gap: ${(props) => props.theme.spacing.xLarge};

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    gap: ${(props) => props.theme.spacing.large};
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const TeamItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-right: 0;
    width: 100%;
  }
`

const usePhotosQuery = () => {
  const query = useStaticQuery(graphql`
    query MyQuery {
      otto: file(relativePath: { eq: "otto.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      olli: file(relativePath: { eq: "olli.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      aleksi: file(relativePath: { eq: "aleksi_old.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pietari: file(relativePath: { eq: "pietu.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      juuso: file(relativePath: { eq: "juuso_small.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return query
}
