import React from "react"
import styled from "styled-components"
import { Spacing } from "../common/Spacing"

import { Container } from "../global"
import LogoDark from "../../images/svg/evexia-logo-side-default.inline.svg"

const PRIVACY_STATEMENT_LINK =
  "https://evexia-policies.s3.eu-west-1.amazonaws.com/Evexia+Privacy+Statement.pdf"
const TERMS_OF_SERVICE_LINK =
  "https://evexia-policies.s3.eu-west-1.amazonaws.com/Evexia+Terms+of+Service.pdf"

const Footer = () => (
  <FooterWrapper id="footer">
    <BrandContainer>
      <LogoSVG />
    </BrandContainer>
    <FooterColumnContainer>
      <FooterColumn>
        <span>Basics</span>
        <ul>
          <li>team@evexia.fi</li>
          <li>Business ID: 3112305-4</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Resources</span>
        <ul>
          <li>
            <a href={PRIVACY_STATEMENT_LINK} target="_blank">
              Privacy Statement
            </a>
          </li>
          <li>
            <a href={TERMS_OF_SERVICE_LINK} target="_blank">
              Terms of Service
            </a>
          </li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <Spacing dir="y" amount="xLarge" />
    <Copyright>© Evexia Oy</Copyright>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.white};
  padding: 32px 0 48px 0;
`

const LogoSVG = styled(LogoDark)`
  width: 120px;
  height: 40px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 32px;
  display: flex;
  align-items: flex-end;
`
const FooterColumnContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  grid-gap: 0 ${(props) => props.theme.spacing.xLarge};

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`

const Copyright = styled(Container)`
  display: flex;
  color: ${(props) => props.theme.color["grey-500"]};
`

const FooterColumn = styled.div`
  margin-top: ${(props) => props.theme.spacing.xLarge};
  span {
    font-size: 16px;
    ${(props) => props.theme.font.body.medium};
    color: ${(props) => props.theme.color["grey-700"]};
  }
  ul {
    list-style: none;
    margin: ${(props) => props.theme.spacing.large} 0;
    padding: 0;
    color: ${(props) => props.theme.color["grey-900"]};
    li {
      margin-bottom: ${(props) => props.theme.spacing.regular};
      ${(props) => props.theme.font.body.regular};
      font-size: 16px;

      a {
        text-decoration: none;
        color: inherit;
        :hover {
          color: ${(props) => props.theme.color["grey-900"]};
        }
      }
    }
  }
`

export default Footer
