import styled from "styled-components"
import { Container } from "../../global"

export const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color["grey-900"]};
  display: flex;
  justify-content: flex-start;
  margin: 0 0 ${(props) => props.theme.spacing.xLarge} 0;
  text-align: left;
`
export const SectionText = styled.p`
  max-width: 800px;
  text-align: left;
  line-height: 1.5;
  ${(props) => props.theme.font_size.smallplus}

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    ${(props) => props.theme.font_size.small}
  }
`

export const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color["grey-500"]};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const TextGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  align-self: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-wrap: wrap;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
