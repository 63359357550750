import styled from "styled-components"

import { Container } from "../../global"

export const BigCTAButton = styled.button`
  ${(props) => props.theme.font.accent.extraBold};
  ${(props) => props.theme.font_size.xsmall};
  color: ${(props) => props.theme.color.white};
  letter-spacing: 0.25px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color["primary-500"]};
  border-radius: 50px;
  padding: 16px 32px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  :hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export const Nav = styled.nav`
  padding: ${(props) => (props.scrolled ? `16px 0` : `24px 0`)};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${(props) =>
    props.scrolled ? props.theme.color.white : null};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  margin: 0 0.75em;
  ${(props) => props.theme.font.accent.medium};
  ${(props) => props.theme.font_size.xsmall};

  a {
    text-decoration: none;
    opacity: 0.9;
    color: ${(props) => props.theme.color["grey-500"]};
  }

  &.active {
    a {
      color: ${(props) => props.theme.color["grey-900"]};
    }
  }
  :hover {
    a {
      color: ${(props) => props.theme.color["grey-900"]};
    }
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${(props) => props.theme.color.regular};
`

export const Brand = styled.div`
  ${(props) => props.theme.font.accent.bold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color["grey-900"]};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${(props) => props.theme.color["primary-500"]};
      text-decoration: none;
    }
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: none;
  }

  button {
    ${(props) => props.theme.font.accent.extraBold};
    letter-spacing: 0.25px;
    ${(props) => props.theme.font_size.xsmall};
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.color["primary-500"]};
    border-radius: 50px;
    padding: 16px 24px;
    text-align: center;
    text-transform: uppercase;
    ${(props) => props.theme.font_size.xxxsmall};
    :hover {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    }
  }
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.sm}) {
      display: none;
    }
  `}
`
