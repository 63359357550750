import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { Spacing } from "../Spacing"
import Icon from "../../../images/svg/linkedin.inline.svg"

const Person = ({ name, role, imageQuery, linkedIn }) => {
  return (
    <PersonWrapper>
      <StyledImage fluid={imageQuery} />
      <Spacing dir="x" amount="large"></Spacing>
      <Spacing dir="y" amount="large"></Spacing>

      <TextWrapper>
        <Name>{name}</Name>
        <Spacing dir="y" amount="small"></Spacing>

        <Role>{role}</Role>
        <Spacing dir="y" amount="regular"></Spacing>

        <LinkedinWrapper href={linkedIn} target="_blank">
          <LinkedinIcon />
        </LinkedinWrapper>
      </TextWrapper>
    </PersonWrapper>
  )
}

const PersonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 436px;

  padding: ${(props) => props.theme.spacing.large};
  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px;
  box-shadow: 0 2px 40px 0 rgb(177 38 37 / 5%),
    0 5px 10px 0 rgb(177 38 37 / 10%);

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 512px;

  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 466px;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    width: 288px;
    flex-direction: column;
    justify-content: center;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  max-width: 300px;
`

const Name = styled.h5`
  ${(props) => props.theme.font_size.regular};

  color: ${(props) => props.theme.color["grey-900"]};
`
const Role = styled.h5`
  ${(props) => props.theme.font_size.small};

  color: ${(props) => props.theme.color["grey-500"]};
`

// const Description = styled.p`
//   font-size: 16px;
//   color: ${(props) => props.theme.color["grey-900"]};
// `

const LinkedinWrapper = styled.a`
  display: flex;
  color: ${(props) => props.theme.color["grey-900"]};

  :hover {
    opacity: 0.5;
  }
`

// const LinkedinText = styled.div`
//   font-size: 28px;
//   ${(props) => props.theme.font.accent.medium};
// `

const LinkedinIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  fill: ${(props) => props.theme.color["accent-500"]};
`

const StyledImage = styled(Img)`
  width: 128px;
  height: 128px;
  border-radius: 8px;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    width: 100%;
    height: 100%;
  }
`

export default Person
