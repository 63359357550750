import React from "react"

import {
  SectionTitle,
  Subtitle,
  StyledContainer,
  TextGrid,
} from "../common/sections/styles"
import { TextItem } from "../common/sections/TextItem"
import { Spacing } from "../common/Spacing"
import { Section } from "../global"

const Solution = () => (
  <Section id="solution" accent="secondary">
    <StyledContainer>
      <Subtitle>Solution</Subtitle>
      <SectionTitle>Smart and patient-oriented software</SectionTitle>
      <Spacing dir="y" amount="xLarge" />

      <TextGrid>
        <TextItem title="Mobile application" iconName="mobile" accent>
          Patients use our mobile application to gather relevant data for the
          doctor.
        </TextItem>

        <TextItem title="Doctor dashboard" iconName="desktop" accent>
          Our algorithms process the data to form an ECOG-grade recommendation
          for the doctor.
        </TextItem>

        <TextItem title="Context removes bias" iconName="bolt" accent>
          The analysed data allows the doctors to also asses their own
          judgement, which reduces bias in the ECOG-grading process.
        </TextItem>
      </TextGrid>
    </StyledContainer>
  </Section>
)

export default Solution
