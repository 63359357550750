import React from "react"
import styled from "styled-components"
import { PropTypes } from "prop-types"
import { Spacing } from "../Spacing"
import { Icon } from "../utils/Icon"

export const TextItem = ({ title, children, iconName, accent = false }) => {
  return (
    <TextItemWrapper>
      {iconName && (
        <>
          <IconWrapper accent={accent}>
            <Icon name={iconName} />
          </IconWrapper>
          <Spacing dir="y" amount="large" />
        </>
      )}
      <TextItemTitle>{title}</TextItemTitle>
      <TextItemContent>{children}</TextItemContent>
    </TextItemWrapper>
  )
}

TextItem.defaultProps = {
  title: ``,
  children: ``,
  iconName: ``,
  accent: false,
}

TextItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  hasIcon: PropTypes.string,
  accent: PropTypes.bool,
}

const TextItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  width: calc(33.33333% - 40px);
  max-width: 380px;
  margin-right: ${(props) => props.theme.spacing.large};

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 100%;
    max-width: 100%;
    margin: 0 0 ${(props) => props.theme.spacing.xLarge};
  }
`

const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  background-color: ${(props) =>
    props.accent
      ? props.theme.color["accent-500"]
      : props.theme.color["primary-500"]};
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

const TextItemTitle = styled.div`
  ${(props) => props.theme.font.accent.medium};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color["grey-900"]};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;

  text-align: center;
`

const TextItemContent = styled.p`
  text-align: left;
`
