import React from "react"
import styled, { css } from "styled-components"

import Bolt from "../../../images/svg/icons/bolt.inline.svg"
import Desktop from "../../../images/svg/icons/desktop.inline.svg"
import Mobile from "../../../images/svg/icons/mobile.inline.svg"
import Pause from "../../../images/svg/icons/pause.inline.svg"
import Rewind from "../../../images/svg/icons/rewind.inline.svg"
import User from "../../../images/svg/icons/user.inline.svg"
import { PropTypes } from "prop-types"

const basicIconStyle = css`
  width: 20px;
  height: 20px;
  fill: ${(props) => (props.fill ? props.fill : props.theme.color.white)};
`

const BoltIcon = styled(Bolt)`
  ${basicIconStyle}
`
const DesktopIcon = styled(Desktop)`
  ${basicIconStyle}
`
const MobileIcon = styled(Mobile)`
  ${basicIconStyle}
`
const PauseIcon = styled(Pause)`
  ${basicIconStyle}
`
const RewindIcon = styled(Rewind)`
  ${basicIconStyle}
`
const UserIcon = styled(User)`
  ${basicIconStyle}
`
export const Icon = ({ name }) => {
  switch (name) {
    case "bolt":
      return <BoltIcon />
    case "desktop":
      return <DesktopIcon />
    case "mobile":
      return <MobileIcon />
    case "pause":
      return <PauseIcon />
    case "rewind":
      return <RewindIcon />
    case "user":
      return <UserIcon />
    default:
      return null
  }
}

Icon.defaultProps = {
  name: ``,
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}
