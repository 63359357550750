import React from "react"

import {
  SectionTitle,
  Subtitle,
  StyledContainer,
  SectionText,
  TextGrid,
} from "../common/sections/styles"
import { TextItem } from "../common/sections/TextItem"
import { Spacing } from "../common/Spacing"
import { Section } from "../global"

const Problem = () => (
  <Section id="problem">
    <StyledContainer>
      <Subtitle>Problem</Subtitle>

      <SectionTitle>Focusing on cancer care</SectionTitle>

      <SectionText>
        In cancer care, every patient is given a grade called ECOG-grade which
        practically measures the performance status of the patient. This is used
        to understand how strong treatment the patient can withstand. However,
        studies show that there are three main problems with it:
      </SectionText>
      <Spacing dir="y" amount="xxLarge" />

      <TextGrid>
        <TextItem title="Subjectivity" iconName="user">
          Currently, the grade is often decided by only one doctor. Research
          shows that doctors often don't agree on the score.
        </TextItem>

        <TextItem title="Recall bias" iconName="rewind">
          Patients are prone to human error when trying to remember their past
          symptoms and activity, which makes the doctor's job harder.
        </TextItem>

        <TextItem title="Grading is too static" iconName="pause">
          Performance status assessment is static as the score is captured only
          during clinical visits, when in fact a patient's PS is dynamic
          throughout treatment and can change daily.
        </TextItem>
      </TextGrid>
    </StyledContainer>
  </Section>
)

export default Problem
